<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div v-if="userId" class="row col-8">
          <v-autocomplete
            style="border-radius:13px;"
            v-model="word"
            :items="userList"
            :filter="filter"
            item-text="fname"
            item-value="id"
            dense
            solo
            outlined
            placeholder="ค้นหาจากชื่อหรือเบอร์โทรศัพท์"
            prepend-inner-icon="mdi-magnify"
            class="app-bar-search flex-grow"
            hide-details
            hide-no-data
            @input.native="onSearch"
            @change="handleChange($event)"
          >
            <template v-slot:[`item`]="{ item }">
              <v-list-item-avatar>
                <img v-if="item.user_profile" :src="item.user_profile" @error="imageError" style="object-fit:cover" />
                <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ item.fname }} {{ item.lname }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.phone }} </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <div class="pl-2 pt-1 text--red text-xl">กรุณาเลือกสมาชิกก่อนสร้างออเดอร์</div>
        </div>
        <user-component v-else></user-component>
      </v-col>
      <v-col cols="12">
        <POS></POS>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import POS from '@/components/POS/POS.vue'
import UserComponent from '@/components/user/UserComponent.vue'

export default {
  components: { POS, UserComponent },
  data() {
    return {
      word: '',
      userList: [],
    }
  },
  methods: {
    imageError(event) {
      event.target.src = require('@/assets/images/avatars/1.png')
    },
    onSearch() {
      var data = {
        word: this.word,
      }
      console.log(data)
      this.$store.dispatch('searchUser', data).then(res => {
        console.log(res)
        this.userList = res
      })
    },
    filter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase()

      const fname = item.fname.toLowerCase()
      const lname = item.lname.toLowerCase()
      const phone = item.phone.toLowerCase()

      return fname.indexOf(searchText) > -1 || lname.indexOf(searchText) > -1 || phone.indexOf(searchText) > -1
    },
    handleChange(id) {
      console.log(id)
      localStorage.setItem('user_id', id)
      location.replace('/openbill')
      this.word = ''
    },
  },
  computed: {
    userId() {
      if (localStorage.getItem('user_id') == 'undefined') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
